import React,{useRef,useState,useEffect} from 'react';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import QRCode from "qrcode";
import "../styles/DefaultLayout.css";
import { Button} from "antd";


const AllQrCode = () => {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const apiEndPoint = "${process.env.REACT_APP_API_URL}/api/settable";
  
  const [tableNo, setTableNo] = useState("");
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [allQr, setAllQr] = useState([]);
  var qrArray = [];

  const getTable = async () => {
    try {
      setLoading(true);
    const { data } = await axios.get(apiEndPoint);
    console.log("database",data);
    setTables(data);
    setGridData(data);
    setLoading(false);
    
    let i = 0;
    // let table_no = [];
    let tableLinkArray = [];
    // let tableNoArray = [];
    //console.log(data);
    // generateAll(data);
    for(i;i<data.length;i++){
      const table_no = data[i].table_no;
      const table_link = data[i].table_link;
      console.log("for data",data);

      QRCode.toDataURL(table_link, {
        width: 800,
        margin: 2,
        color: {
          dark: '#335383FF',
          light: '#EEEEEEFF'
        }
      }, (error, qrcodelink) => {
        if (error) {
          return error;
        }
    
    // tableLinkArray.push(qrcodelink);
    tableLinkArray.push({
            "table_no":table_no,
            "link": qrcodelink
          });
    // qrArray.push(qrcodelink);
    // console.log("qrArray",qrArray);
  
    })
     setAllQr(tableLinkArray);
    }
    }
   catch (error) { 
    return error;
}
};

const fetchedData = gridData.map((item) => ({
  ...item,
  
  }));
  
  const modifiedData = fetchedData.map(({body,...item}) => ({
  ...item,
  key: item._id,
  tableNo:item.table_no,
  link:item.table_link,
  }));
  
  useEffect(() => {
      getTable();
      
    },[]);

  return (<>
  <div class="print__section">
    <div class="container">
      <div class="row">
        <div class="col-md-12"><br/>
        <div className="d-flex justify-content-between">
          <p></p>
          <Button type="primary" onClick={handlePrint} className="print__button">  Print </Button> </div>
          <div ref={componentRef} className="card">
            <div class="float__start" style={{ marginLeft: '10px' }}>
              <h3 class="card-title mb-0">All QR code</h3>
            </div>
            <hr />
            <div class="float__infoss">
            <div class="grid-container">
            
            {allQr.map((data) => (
            <div class="grid-item"><img width={150} src={data.link} /><br/>Table {data.table_no}
          </div>
          ))}
          </div>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>     
  )
}

export default AllQrCode