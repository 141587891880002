import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './pages/Homepage';
import ItemPage from './pages/ItemPage';
import SetTable from './pages/SetTable';
import SetTax from './pages/SetTax';
import SetMember from './pages/SetMember';
import AllQrCode from './pages/AllQrCode';
import SetCategory from './pages/SetCategory';
import SetMenu from './pages/SetMenu';
import TableSetting from './pages/TableSetting';
import Bills from './pages/Bills';
import Staff from './pages/Staff';
import Report from './pages/Report';
import Login from './pages/Login';
import PrivateRoute from './routes/privateRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <PrivateRoute>
            <Homepage />
          </PrivateRoute>
        } />
        <Route path="/items" element={
          <PrivateRoute>
            <ItemPage />
          </PrivateRoute>
        } />
        <Route path="/category" element={
          <PrivateRoute>
            <SetCategory />
          </PrivateRoute>
        } />
        <Route path="/setTable" element={
          <PrivateRoute>
            <SetTable />
          </PrivateRoute>
        } />
        <Route path="/tables" element={
          <PrivateRoute>
            <TableSetting />
          </PrivateRoute>
        } />
        <Route path="/setTax" element={
          <PrivateRoute>
            <SetTax />
          </PrivateRoute>
        } />
        <Route path="/setMember" element={
          <PrivateRoute>
            <SetMember />
          </PrivateRoute>
        } />
        <Route path="/allQrCode" element={
          <PrivateRoute>
            <AllQrCode />
          </PrivateRoute>
        } />
        <Route path="/menu" element={
          <PrivateRoute>
            <SetMenu />
          </PrivateRoute>
        } />
        <Route path="/bills" element={
          <PrivateRoute>
            <Bills />
          </PrivateRoute>
        } />
        <Route path="/staff" element={
          <PrivateRoute role="admin">
            <Staff />
          </PrivateRoute>
        } />
        <Route path="/report" element={
          <PrivateRoute role="admin">
            <Report />
          </PrivateRoute>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

//export function ProtectedRoute({children}){
  //if(localStorage.getItem('auth')){
  //  return children
  //}
  //else{
   // return <Navigate to="/login" />;
 // }
//}