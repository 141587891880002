import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link,useNavigate } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  CopyOutlined,
  FileTextOutlined,
  SettingOutlined,
  TeamOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "../styles/DefaultLayout.css";
import { getUserRole } from '../utils/auth';
const { Header, Sider, Content } = Layout;
const SubMenu = Menu.SubMenu;



const DefaultLayout = ({ children }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const userRole = getUserRole();

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{
        height: 1320
      }}>
        <div className="logo">
          <h1 className="text-center text-light font-wight-bold mt-4">POS</h1>
        </div><br />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={window.location.pathname}

        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to="/">Table</Link>
          </Menu.Item>
          <Menu.Item key="/menu" icon={<MenuOutlined />}>
            <Link to="/items">Menu</Link>
          </Menu.Item>
          <Menu.Item key="/category" icon={<AppstoreOutlined />}>
            <Link to="/category">Category</Link>
          </Menu.Item>
          <Menu.Item key="/bills" icon={<CopyOutlined />}>
            <Link to="/bills">Bills</Link>
          </Menu.Item>
          {/* <Menu.Item key="/SetMember" icon={<TeamOutlined />}>
            <Link to="/SetMember">Members</Link>
          </Menu.Item> */}

          {userRole === 'admin' && (
            <>
              <Menu.Item key="/report" icon={<FileTextOutlined />}>
                <Link to="/report">Report</Link>
              </Menu.Item>
              <SubMenu theme="dark" key="SubMenu" title="Setting" icon={<SettingOutlined />}>
                <Menu.Item key="table">
                  <Link to='/tables'>Tables</Link>
                </Menu.Item>
                <Menu.Item key="tax">
                  <Link to='/SetTax'>Taxes</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="/staff" icon={<UserOutlined />}>
                <Link to="/staff">Staff</Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item key="/logout" icon={<LogoutOutlined />}
          onClick={() => {
            localStorage.removeItem('auth')
            navigate('/login')
          }}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ height: 1350 }} >
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default DefaultLayout;