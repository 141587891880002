import React from "react";
import './BillList.css'; // If you need custom styles for the receipt

const Receipt = ({ orders, taxes }) => {
    if (!orders.length) return <div>No orders available.</div>;

    const orderItems = orders.flatMap(order => order.result);

    const calculateTotalWithTaxes = (orders) => {
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
        return (totalWithoutTax + totalTax).toFixed(2);
    };

    const calculateTotalWithoutTaxes = (orders) => {
        return orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        ).toFixed(2);
    };

    const subTotal = calculateTotalWithoutTaxes();
    const totalWithTaxes = calculateTotalWithTaxes();
    const totalTax = (totalWithTaxes - subTotal).toFixed(2);

    const handlePrint = () => {
        const printWindow = window.open('', '', 'width=600,height=400');
        const printContent = document.getElementById('printable-receipt').innerHTML;

        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Receipt</title>
                    <style>
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                                font-family: Arial, sans-serif;
                            }
                            .receipt-container {
                                max-width: 58mm;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    return (
    	<div>
         <div id="printable-receipt" className="receipt-container">
            <div className="receipt-header">
                <p>**Receipt**</p>
                <p><strong>Restaurant Name</strong></p>
                <p>No 1-2, Jalan 1/1,<br /> 46000 Petaling Jaya,<br /> Selangor</p>
            </div>
            <div className="receipt-details">
                <p><strong>Table Number:</strong> {orders[0]?.order_tablenumber}</p>
                <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
            </div>
            <table className="receipt-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((item, index) => (
                        <tr key={index}>
                            <td>{item.orderArray_productName}</td>
                            <td>{item.orderArray_quantity}</td>
                            <td>{(Number(item.orderArray_quantity) * Number(item.orderArray_price)).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="receipt-subtotal">
                Sub Total: RM{" "}
                <span>{calculateTotalWithoutTaxes(orders)}</span>
            </div>
            <div className="receipt-subtotal">
                Total Tax (16%): RM{" "}
                <span>{(calculateTotalWithTaxes(orders) - calculateTotalWithoutTaxes(orders)).toFixed(2)}</span>
            </div>
            <div className="receipt-total">
                <p>--------------------------</p>
                Grand Total: RM{" "}
                <span>{calculateTotalWithTaxes(orders)}</span>
            </div>
            <div className="receipt-footer">
                <p>Thank you! Please come again :)</p>
            </div>
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                <button onClick={handlePrint}>
                    Print Order Receipt
                </button>
            </div>
        </div>
        </div>
    );
};

export default Receipt;
