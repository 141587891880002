import React from 'react';
import DefaultLayout from '../components/DefaultLayout';

const Report = () => {
  return (
    <DefaultLayout>
      <h1>Report</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" alt="Sad No Report" style={{ width: '50%', margin: 'auto' }} />
        <h2 style={{ textAlign: 'center', fontStyle: 'italic' }}>This report function is coming Soon.</h2>
      </div>
    </DefaultLayout>
  );
};

export default Report;
