import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, Select, message } from "antd";
import { FilterDropdown } from "antd/lib/table/hooks/useFilter";
import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import slugify from "slugify";

import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const ProductsTable = () => {

  const [itemsData, setItemsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [newPid, setNewPid] = useState('');

  const handleCategoryFilter = (value, record) => {
    return record.category === value;
  };

  const handleCategoryFiltersChange = (filters) => {
    setCategoryFilters(filters);
  };
  const getAllItems = async () => {
    try {

      const { data } = await axios.get("/api/products/get-product");

      setItemsData(data);

      console.log(data);

    } catch (error) {
      console.log(error);
    }

  };

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get("/api/menus/get-category");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    getAllItems();
    getAllCategories();
  }, []);


  //handle delete
  const handleDelete = async (record) => {
    try {


      await axios.post("/api/products/delete-product", { productId: record._id });
      message.success('Food Deleted Successfully')
      getAllItems();
      setPopupModal(false);

    } catch (error) {
      message.success('Something Went Wrong')
      console.log(error);
    }
  }

  //able data
  const columns = [

    { title: "Name", dataIndex: "name" },
    { title: "Description", dataIndex: "description" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img src={record.image}
          alt={record.name} height="60" width="60" />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: categories.map(category => ({
        text: category.name,
        value: category.name,
      })),
      onFilter: (value, record) => record.category === value,
    },


    { title: "Price", dataIndex: "price" },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "Available" : "Unavailable"),
      filters: [
        {
          text: "Available",
          value: true,
        },
        {
          text: "Not Available",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EditOutlined
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              setEditItem(record);
              setPopupModal(true);
            }} />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>

        </div>
      ),

    },
  ];
  //handle name change
  const handleNameChange = (event) => {
    const { value } = event.target;
    Form.setFieldsValue({ slug: slugify(value) });
  };

  //handle submit
  const handleSubmit = async (value) => {
    const { name } = value;

    let slug;
    if (editItem === null) {
      const res = await axios.get("/api/products/latest-pid");
      const latestPid = res.data.pid;
      const newPid = "P" + String(parseInt(latestPid.slice(1)) + 1).padStart(7, "0");


      try {

        const dateTime = new Date();
        const createdAt = dateTime.toLocaleString();

        slug = slugify(name);

        const newValues = { ...value, slug, pid: newPid, createdAt};

        const res = await axios.post("/api/products/add-product", newValues);
        message.success('Food Added Successfully')
        getAllItems();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    } else {
      try {
        slug = slugify(name);
        await axios.put("/api/products/edit-product", { ...value, slug, productId: editItem._id});
        message.success('Food Updated Successfully')
        getAllItems();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong')
        console.log(error);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Menu List</h1>
        <Button type="primary" onClick={() => setPopupModal(true)}>Add Menu</Button>
      </div>

      <Table columns={columns} dataSource={itemsData} bordered />


      {
        popupModal && (
          <Modal title={`${editItem !== null ? 'Edit Menu' : 'Add New Menu'}`}
            open={popupModal}
            onCancel={() => {
              setEditItem(null);
              setPopupModal(false);
            }}
            footer={false}>
            <Form layout="vertical" initialValues={editItem} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { min: 3, message: 'Name must be at least 3 characters' },
                ]}
              >
                <Input onChange={handleNameChange} />
              </Form.Item>
              <Form.Item name="slug" label="slug" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: 'Please enter a description' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                rules={[
                  { required: true, message: 'Please enter the price' },
                  { pattern: /^[0-9]+(\.[0-9]{1,2})?$/, message: 'Please enter a valid price' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="quantity"
                label="Max Quantity"
                rules={[
                  { required: true, message: 'Please enter the quantity' },
                  { pattern: /^[0-9]+$/, message: 'Quantity must be a number' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="image"
                label="Image URL"
                rules={[
                  { required: true, message: 'Please enter an image URL' },
                  { type: 'url', message: 'Please enter a valid URL' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="pid" label="pid" hidden>
                <Input value={newPid} />
              </Form.Item>

              <Form.Item
                name="category"
                label="Category"
                rules={[
                  { required: true, message: 'Please select a category' },
                ]}
              >
                <Select>
                  {categories.map((category) => (
                    <Select.Option key={category._id} value={category.name}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="status"
                label="Availability"
                rules={[
                  { required: true, message: 'Please select availability status' },
                ]}
              >
                <Select>
                  <Select.Option value={true}>Available</Select.Option>
                  <Select.Option value={false}>Not Available</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="createdAt" label="Created At" hidden>
                <Input value={new Date()} />
              </Form.Item>

              <div className="d-flex justify-content-end">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>

            </Form>
          </Modal>
        )
      }

    </DefaultLayout>
  );


};

export default ProductsTable;
