import React, { useState } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import './Payment.css';

const PaymentPage = ({ tableNumber, totalWithTaxes, orders, onPaymentValidation }) => {
  const [paymentMethod, setPaymentMethod] = useState(null); // null = no method selected
  const [cashAmount, setCashAmount] = useState(''); // Store cash input as string for easier appending
  const [amountToPay, setAmountToPay] = useState(totalWithTaxes); // Example amount to pay
  const [transactionId, setTransactionId] = useState(''); // Transaction ID for transfer methods

  // Handle numpad input for cash payments (append digits)
  const handleCashInput = (value) => {
    setCashAmount((prevAmount) => prevAmount + value); // Append the new digit to the cash amount
  };

  // Handle quick addition of +10, +20, +50
  const handleQuickAdd = (value) => {
    setCashAmount((prevAmount) => {
      const currentAmount = parseFloat(prevAmount || '0'); // Convert existing amount to number
      return (currentAmount + value).toString(); // Add the value and convert back to string
    });
  };

  // Calculate balance for cash payments
  const calculateBalance = () => {
    return parseFloat(cashAmount || '0') - amountToPay;
  };

  // Handle transaction ID input
  const handleTransactionIdChange = (e) => {
    setTransactionId(e.target.value);
  };

  // Handle payment method selection
  const selectPaymentMethod = (method) => {
    setPaymentMethod(method);
    setCashAmount(''); // Reset cash amount when switching methods
    setTransactionId(''); // Reset transaction ID
  };

  // Handle clearing the cash input
  const clearCashInput = () => {
    setCashAmount('');
  };

  // Function to submit the payment
  const submitPayment = async () => {
    const paymentData = {
        method: paymentMethod, // This should be defined based on user selection
        transactionId: paymentMethod === 'cash' ? null : transactionId,
        amount: paymentMethod === 'cash' ? parseFloat(cashAmount) : amountToPay,
        orderDetails: orders, // Include order details if applicable
    };

    console.log('Payment Data:', paymentData); // Log to ensure it's correct

    try {
        const response = await axios.post('/api/payments', paymentData);
        console.log('Payment stored successfully:', response.data);
    } catch (error) {
        console.error('Error storing payment:', error);
    }

    onPaymentValidation(paymentData);
};

  return (
    <div className="payment-container">
      <header className="payment-header">
        <div className="table-info">
            <h2>Table {tableNumber}</h2>
        </div>
      </header>

      <div className="payment-body">
        {/* Payment Method Selection */}
        <div className="payment-methods">
            <button
                onClick={() => selectPaymentMethod('cash')}
                className={paymentMethod === 'cash' ? 'selected' : ''}
            >
                Cash
            </button>
            <button
                onClick={() => selectPaymentMethod('transfer2')}
                className={paymentMethod === 'transfer2' ? 'selected' : ''}
            >
                Visa
            </button>
            <button
                onClick={() => selectPaymentMethod('transfer3')}
                className={paymentMethod === 'transfer3' ? 'selected' : ''}
            >
                Tng
            </button>
            <button
                onClick={() => selectPaymentMethod('transfer4')}
                className={paymentMethod === 'transfer4' ? 'selected' : ''}
            >
                Duitnow
            </button>
        </div>

        {/* Display Cash Calculator or Transaction ID based on selected payment method */}
        <div className="payment-center">
          {paymentMethod === 'cash' && (
            <>
              <div className="total-display">
                <h3>RM {amountToPay} </h3>
                <h6>Cash Entered: RM {cashAmount || '0.00'}</h6>
                <h5>Balance: RM {calculateBalance() >= 0 ? calculateBalance().toFixed(2) : '0.00'}</h5>
              </div>
              <div className="numpad">
                <div className="numpad-row">
                  <button onClick={() => handleCashInput('1')}>1</button>
                  <button onClick={() => handleCashInput('2')}>2</button>
                  <button onClick={() => handleCashInput('3')}>3</button>
                  <button onClick={() => handleQuickAdd(10)}>+10</button>
                </div>
                <div className="numpad-row">
                  <button onClick={() => handleCashInput('4')}>4</button>
                  <button onClick={() => handleCashInput('5')}>5</button>
                  <button onClick={() => handleCashInput('6')}>6</button>
                  <button onClick={() => handleQuickAdd(20)}>+20</button>
                </div>
                <div className="numpad-row">
                  <button onClick={() => handleCashInput('7')}>7</button>
                  <button onClick={() => handleCashInput('8')}>8</button>
                  <button onClick={() => handleCashInput('9')}>9</button>
                  <button onClick={() => handleQuickAdd(50)}>+50</button>
                </div>
                <div className="numpad-row">
                  <button onClick={() => handleCashInput('0')}>0</button>
                  <button onClick={() => handleCashInput('.')}>.</button>
                  <button onClick={clearCashInput}>Clear</button>
                </div>
                <div className="numpad-row">
                  <button 
                    onClick={submitPayment} // Call the submitPayment function
                    className="pay-button"
                  >
                    Validate
                  </button>
                </div>
              </div>
            </>
          )}

          {(paymentMethod === 'transfer2' || paymentMethod === 'transfer3' || paymentMethod === 'transfer4') && (
            <div className="transaction-id-section">
                <h3>RM {amountToPay} </h3>
              <h5>Enter Transaction ID:</h5>
              <input
                type="text"
                value={transactionId}
                onChange={handleTransactionIdChange}
                placeholder="Transaction ID"
              />
              <button onClick={submitPayment}>Submit Transaction</button> {/* Call the submitPayment function */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
