import React from "react";
import './BillList.css'; // Custom styles for the receipt

const OrderReceipt = ({ orders, tableNumber }) => {
    // Handle cases where orders might be undefined/null
    if (!orders || !orders.length) return <div>No orders available.</div>;

    // Safely flatten the order items, fallback to an empty array if result is null/undefined
    const orderItems = orders.flatMap(order => order.result || []);

    const handlePrint = () => {
        const printWindow = window.open('', '', 'width=600,height=400');
        const printContent = document.getElementById('printable-order-receipt').innerHTML;

        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Order Receipt</title>
                    <style>
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                                font-family: Arial, sans-serif;
                            }
                            .receipt-container {
                                max-width: 58mm;
                                margin: 0;
                                padding: 0;
                            }
                            table {
                                width: 100%;
                                border-collapse: collapse;
                            }
                            th, td {
                                border: none;
                                padding: 5px;
                                text-align: left;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${printContent}
                </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.focus();
        printWindow.print();

        // Ensure the window closes after printing is complete
        setTimeout(() => {
            printWindow.close();
        }, 500);
    };

    return (
        <div>
            <div id="printable-order-receipt" className="receipt-container">
                <div className="receipt-header">
                    <p>**Order Receipt**</p>
                    <p><strong>Table Number:</strong> {tableNumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
                </div>
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.orderArray_productName}</td>
                                <td>{item.orderArray_quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                <button onClick={handlePrint}>
                    Print Order Receipt
                </button>
            </div>
        </div>
    );
};

export default OrderReceipt;
