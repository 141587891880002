import React, {useEffect} from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

//import {useDispatch} from 'react-redux';

const Login = () => {

  const navigate = useNavigate();

  const handleSubmit = async (value) => {
    try {
      const { userId, password } = value;
      const res = await axios.post("/api/users/login", value);
      message.success('Login successful');
      
      const token = res.data.token;
      const decoded = jwtDecode(token);
      
      localStorage.setItem('auth', JSON.stringify({token, role:decoded.role}));
      navigate("/");

    } catch (error) {
      message.error('Login failed');
      console.log(error);
    }
  };

 //current log in user
 useEffect(() => {
  if (localStorage.getItem('auth')) {
    navigate('/');
  }
}, [navigate]);

  return (
    <>
      <div className='register'>
        <div className="register-form">
        <h1>POS APP</h1>
        <h3>Login Page</h3>

          <Form layout="vertical" onFinish={handleSubmit}>

          <Form.Item name="userId" label="User ID">
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input type="password"/>
          </Form.Item>

            <div className="d-flex justify-content-end">
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
