import React, { useState, useEffect } from "react";
import tableImage from '../assets/images/chair.png';
import { Card, Modal, Button, Table, Collapse, message, Popconfirm } from 'antd';
import axios from 'axios';
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import './BillList.css';
import PaymentPage from "../pages/PaymentPage";
import Receipt from '../components/Receipt';

const TableList = ({ table }) => {
    const { Meta } = Card;
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [tableNumber, setTableNumber] = useState(table.table_number);
    const [tableOrders, setTableOrders] = useState({});
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [taxes, setTaxes] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const onChange = (key) => {
        console.log(key);
    };

    const getAllOrders = async () => {
        try {
          const { data } = await axios.get("/api/orders/get-order");
          setOrders(data);
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        const fetchTableOrders = async () => {
            try {
                const { data: ordersData } = await axios.get(`/api/orders/get-bill?table=${tableNumber}`);
                const tableOrdersMap = {};
                ordersData.forEach(order => {
                    const { order_tablenumber } = order;
                    if (!tableOrdersMap[order_tablenumber]) {
                        tableOrdersMap[order_tablenumber] = [];
                    }
                    tableOrdersMap[order_tablenumber].push(order);
                });
                setTableOrders(tableOrdersMap);
            } catch (error) {
                console.log('Error fetching orders:', error);
            }
        };
        fetchTaxes();
        fetchTableOrders();
    }, [tableNumber]);

    const fetchData = async (tableNumber) => {
        try {
            const ordersData = tableOrders[tableNumber] || [];
            setOrders(ordersData);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(tableNumber);
    }, [tableNumber, tableOrders]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramTableNumber = urlParams.get('table');

        // If the table number in the query parameter matches the current table, open the modal
        if (paramTableNumber && paramTableNumber === String(tableNumber)) {
            setOpen(true);
        }
    }, [tableNumber]);

    const handleDeleteOrder = async (record) => {
    try {
      await axios.post("/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully')
      getAllOrders();
    } catch (error) {
      message.error('Something Went Wrong')
      console.log(error);
    }
  }

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleOkClick = async () => {
        try {
            // Get unique oids from orders
            const uniqueOids = [...new Set(orders.map(order => order.oid))];

          // Create an array of orders to be updated
          const ordersToUpdate = uniqueOids.map(oid => ({
                    orderId: oid,
                    order_progressstatus: "Paid"
          }));

          // Send a single request with the orders array
          const response = await axios.put('/api/orders/edit-order', {
            orders: ordersToUpdate
          });
      
          if (response.status === 200) {
                // alert('All orders updated to Paid!');
            } else {
                console.error('Some orders failed to update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchTaxes = async () => {
        try {
            const response = await axios.get('/api/taxes/get-taxes');
            setTaxes(response.data);
        } catch (error) {
            console.error("Error fetching taxes:", error);
            message.error("Failed to fetch taxes.");
        }
    };

    const { Panel } = Collapse;

    const handleShowReceipt = (orderId) => {
        setSelectedOrderId(orderId); // Set the selected order ID when showing the receipt
        setShowReceiptModal(true);
    };

    const calculateTotalWithTaxes = (order) => {
        const orderItems = orders.flatMap(order => order.result);
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
        return (totalWithoutTax + totalTax).toFixed(2);
    };

    const calculateTotalWithoutTaxes = (order) => {
        const orderItems = orders.flatMap(order => order.result);
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );

        return (totalWithoutTax).toFixed(2);
    };

    const handlePaymentValidation = async (paymentData) => {
        try {
            // 1. Send payment data to the payment API
            const response = await axios.post('/api/payments', paymentData);
            
            if (response.status === 201) {
                console.log('Payment successful:', response.data);
                
                // 2. Update order status to 'Paid'
                await handleOkClick(); // This function already updates order status
    
                // 3. Close payment modal and show receipt modal
                setShowPaymentModal(false); // Close payment modal
                setShowReceiptModal(true); // Show receipt modal
            } else {
                alert('Payment failed. Please try again.');
            }
        } catch (error) {
            console.error('Error during payment validation:', error);
            alert('An error occurred while processing the payment.');
        }
    };

    const printReceipt = () => {
    // Print receipt logic or open receipt modal
    setShowReceiptModal(true);
    // After showing the receipt, you might want to print it or handle it accordingly
    };

    return (
        <div>
            <Card
                hoverable
                style={{ width: 230, marginBottom: 20 }}
                cover={<img alt={table.table_number} src={tableImage} />}
                onClick={() => setOpen(true)} // This allows opening the modal when clicking the card as well
            >
                <Meta
                    title={`Table ${tableNumber}`}
                />
            </Card>

            <Modal
                open={open}
                title={`Table ${tableNumber}`}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Back
                    </Button>,
                    <Button
                        key="payment"
                        type="primary"
                        onClick={() => setShowPaymentModal(true)}
                        disabled={orders.length === 0}
                    >
                        Payment
                    </Button>,
                    <Button
                        key="show-receipt"
                        type="primary"
                        loading={loading}
                        onClick={() => handleShowReceipt(orders[0]?._id)}
                        disabled={orders.length === 0}
                    >
                        Show Receipt
                    </Button>,
                ]}
            >
                <Collapse defaultActiveKey={orders.map((order) => order.oid)} onChange={onChange}>
                    {orders.map((order) => (
                        <Panel header={<strong>Order ID: #{order.oid}</strong>} key={order.oid}>
                            <Table
                                bordered
                                columns={[
                                    {
                                        title: 'No',
                                        key: 'index',
                                        render: (text, record, recordIndex) => (
                                            <span>{recordIndex + 1}</span>
                                        ),
                                    },
                                    {
                                        title: "Food Name",
                                        dataIndex: "orderArray_productName",
                                    },
                                    {
                                        title: "Price(RM)",
                                        dataIndex: "orderArray_price",
                                        render: (orderArray_price) => {
                                            const formattedPrice = orderArray_price.toLocaleString("en-MY", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            });
                                            return `RM${formattedPrice}`;
                                        },
                                    },
                                    {
                                        title: "Quantity",
                                        dataIndex: "orderArray_quantity",
                                    },
                                    {
                                        title: "Total Price(RM)",
                                        render: (_, record) => {
                                            const totalPrice = (record.orderArray_price * record.orderArray_quantity).toFixed(2);
                                            return `RM${totalPrice}`;
                                        },
                                    },
                                    {
                                        title: "Actions",
                                        render: (_, record) => (
                                            <div>
                                                <CheckOutlined
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleShowReceipt(order._id)} // Use the correct order ID
                                                />
                                                <Popconfirm
                                                    title="Are you sure you want to delete this record?"
                                                    onConfirm={() => handleDeleteOrder(record)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <DeleteOutlined
                                                        style={{ cursor: "pointer", marginLeft: 8 }}
                                                    />
                                                </Popconfirm>
                                            </div>
                                        ),
                                    },
                                ]}
                                dataSource={order.result || []}
                            />
                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.3em" }}>
                                Total Price: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                    {calculateTotalWithoutTaxes(order)}
                                </span>
                            </div>

                            <div style={{ textAlign: "right", fontSize: "1.3em" }}>
                                Total Tax: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                    {(calculateTotalWithTaxes(order) - calculateTotalWithoutTaxes(order)).toFixed(2)}
                                </span>
                            </div>

                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.5em" }}>
                                Total Price with Taxes: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                                    {calculateTotalWithTaxes(order)}
                                </span>
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </Modal>

            <Modal
                title="Receipt"
                visible={showReceiptModal}
                onCancel={() => setShowReceiptModal(false)}
                onOk={() => {
                    window.print();
                    setTimeout(() => {
                        setShowReceiptModal(false);
                        window.location.reload();
                      }, 500); 
                }}
            >
                <Receipt orders={orders} taxes={taxes} />
            </Modal>

            <Modal
                title="Payment"
                visible={showPaymentModal}
                onCancel={() => setShowPaymentModal(false)}
                footer={null}
            >
                <PaymentPage
                    tableNumber={tableNumber}
                    totalWithTaxes={calculateTotalWithTaxes(orders)}
                    orders={orders.flatMap(order => order.result)}
                    onPaymentValidation={handlePaymentValidation} // Pass the validation function
                />
            </Modal>
        </div>
    );
};

export default TableList;