import React, { useState, useEffect } from "react";
import { Popconfirm, Modal, Button, Table, Form, Input, message } from "antd";
import axios from "axios";
import DefaultLayout from '../components/DefaultLayout';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const CategorySetting = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [popupModal, setPopupModal] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/menus/get-category");
      setCategoriesData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleDelete = async (record) => {
    try {
      await axios.post("/api/menus/delete-category", { categoryId: record._id });
      message.success('Category Deleted Successfully');
      getAllCategory();
      setPopupModal(false);
    } catch (error) {
      message.success('Something Went Wrong');
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (text, record, recordIndex) => (
        <span>{recordIndex + 1}</span>
      ),
    },
    { title: "Category Name", dataIndex: "name" },
    {
      title: "Image",
      dataIndex: "image",
      render: (image, record) => (
        <img src={record.image} alt={record.name} height="60" width="60" />
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id, record) => (
        <div>
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditCategory(record);
              setPopupModal(true);
            }}
          />
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              style={{ cursor: "pointer", marginLeft: 8 }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleSubmit = async (values) => {
    const updatedValues = { ...values, slug: values.name };

    if (editCategory === null) {
      try {
        const dateTime = new Date();
        const createdAt = dateTime.toLocaleString();
        const newValues = { ...updatedValues, createdAt };
        await axios.post("/api/menus/add-category", newValues);
        message.success('Category Added Successfully');
        getAllCategory();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong');
        console.log(error);
      }
    } else {
      try {
        await axios.put("/api/menus/edit-category", { ...updatedValues, categoryId: editCategory._id });
        message.success('Category Updated Successfully');
        getAllCategory();
        setPopupModal(false);
      } catch (error) {
        message.success('Something Went Wrong');
        console.log(error);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="d-flex justify-content-between">
        <h1>Category List</h1>
        <Button type="primary" onClick={() => setPopupModal(true)}>Add Category</Button>
      </div>
      <Table columns={columns} dataSource={categoriesData} bordered />
      {
        popupModal && (
          <Modal
            title={`${editCategory !== null ? 'Edit Category' : 'Add New Category'}`}
            open={popupModal}
            onCancel={() => {
              setEditCategory(null);
              setPopupModal(false);
            }}
            footer={false}
          >
            <Form layout="vertical" initialValues={editCategory} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Category Name"
                rules={[{ required: true, message: 'Please input the category name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="image"
                label="Image URL"
                rules={[
                  { required: true, message: 'Please input the image URL!' },
                  { type: 'url', message: 'Please enter a valid URL!' }
                ]}
              >
                <Input />
              </Form.Item>
              <div className="d-flex justify-content-end">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Modal>
        )
      }
    </DefaultLayout>
  );
};

export default CategorySetting;
