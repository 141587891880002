import React, { useEffect, useState } from "react";
import DefaultLayout from '../components/DefaultLayout';
import axios from 'axios';
import { Row, Col } from 'antd';

import TableList from "../components/TableList";


const Homepage = () => {

  const [tablesData, setTablesData] = useState([]);
  useEffect(() => {
    const getAllItems = async () => {
      try {
        const { data } = await axios.get("/api/tables/get-table");

        setTablesData(data);
        console.log(data);

      } catch (error) {
        console.log(error);
      }

    };
    getAllItems();
  }, []);

  return (
    <DefaultLayout>

      <Row>
        {
          tablesData.map(table => (
            <Col xs={24} lg={6} md={12} sm={6}>

              <TableList table={table}></TableList>
            </Col>

          ))
        }
      </Row>
    </DefaultLayout>
  );
};

export default Homepage;